import * as handTrack from './vic';
import { captureCamera, drawToVideo } from './utils';

function stretch(progress, start, end, targetStart = 0, targetEnd = 1) {
  if (progress < start) return targetStart;
  if (progress > end) return targetEnd;
  return ((targetEnd - targetStart) / (end - start)) * (progress - start) + targetStart;
}

function transScore(label, score) {
  let res = 0;
  if (label === 'closed') {
    res = stretch(Number(Number(score).toFixed(1)), 0.6, 0.9, 0, 0.5);
  } else {
    res = stretch(Number(Number(score).toFixed(1)), 0.6, 0.9, 0.5, 1);
  }
  return res;
}

function findPrediction(predictions) {
  if (!predictions.length) return;
  if (predictions.length === 1) return predictions[0];

  let index;
  let max = 0;
  for (let i = 0; i < predictions.length; i++) {
    const area = predictions[i].bbox[2] * predictions[i].bbox[3];
    if (max < area) {
      max = area;
      index = i;
    }
  }
  return predictions[index];
}

async function detect(video, model, update) {
  const predictions = await model.detect(video);
  const track = findPrediction(predictions);

  if (update && predictions.length) {
    console.log(predictions);
    update(
      {
        coordinates: [track.bbox[0] + track.bbox[2] / 2, track.bbox[1] + track.bbox[3]],
        fist: transScore(track.label, track.score),
        hasHand: !!track,
      },
      predictions.find((v) => ['open', 'closed'].includes(v.label)),
    );
  }

  // window.setTimeout(() => {
  //   detect(video, model, update);
  // }, 100);

  window.requestAnimationFrame(() => {
    detect(video, model, update);
  });
}

export async function initDetect({ video, update }) {
  const model = await handTrack.load({
    flipHorizontal: false,
    outputStride: 16,
    imageScaleFactor: 1,
    maxNumBoxes: 20,
    iouThreshold: 0.2,
    scoreThreshold: 0.6,
    modelType: 'ssd320fpnlite',
    modelSize: 'small',
    labelMap: {
      1: 'open',
      2: 'closed',
    },
  });
  console.log('model ready');

  const stream = await captureCamera();
  console.log('stream ready');

  await drawToVideo(video, stream);
  console.log('video ready');

  detect(video, model, update);
}
