<template lang="pug">
.wrapper
  video.video(ref='video' playsinline)
  canvas.canvas(ref='canvas')
  .cursor(ref='cursor') 
  .poster(v-if='isShow') 加载中...
</template>

<script>
import * as THREE from 'three';
import { onMounted } from 'vue';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { loadFbx, loadGltf, loadTexture } from '../Exhibition/core/load';
import { ref } from 'vue';
import { initDetect } from './vic-detect';
// import { initDetect } from './yoha-detect';

export default {
  name: 'Phone',
  setup() {
    const canvas = ref(null);
    const cursor = ref(null);
    const video = ref(null);
    const isShow = ref(true);

    onMounted(async () => {
      document.addEventListener(
        'WeixinJSBridgeReady',
        () => {
          video.value.play();
        },
        false,
      );
      const onResize = () => {
        canvas.value.width = window.innerWidth * 2;
        canvas.value.height = window.innerHeight * 2;
      };
      window.addEventListener('resize', onResize);
      onResize();

      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        1,
        1000,
      );
      const renderer = new THREE.WebGLRenderer({
        antialias: true,
        alpha: true,
        canvas: canvas.value,
      });

      camera.position.set(0, 0, 30);
      scene.add(camera);

      const pic1 = await loadTexture('assets/models/notebook/map/1.jpg', new THREE.TextureLoader());
      const pic2 = await loadTexture('assets/models/notebook/map/2.jpg', new THREE.TextureLoader());
      const pic3 = await loadTexture('assets/models/notebook/map/3.jpg', new THREE.TextureLoader());
      const env = await loadTexture('assets/models/notebook/map/4.jpg', new THREE.TextureLoader());

      [env].forEach((t) => {
        t.mapping = THREE.EquirectangularReflectionMapping;
        t.minFilter = THREE.LinearMipmapLinearFilter;
        t.magFilter = THREE.NearestFilter;
      });

      const model = await loadFbx('assets/models/notebook/notebook.fbx', {
        onProgress: (p) => {},
      });

      const obj = model;
      obj.visible = false;
      obj.traverse((child) => {
        if (child.isMesh) {
          switch (child.name) {
            case '04_C面':
              child.material = new THREE.MeshStandardMaterial({
                color: new THREE.Color(0xffffff),
                emissive: new THREE.Color(0x242424),
                roughness: 0.3,
                metalness: 1,
                map: pic1,
                envMap: env,
              });
              break;
            case '05_键盘':
              child.material = new THREE.MeshStandardMaterial({
                color: new THREE.Color(0xffffff),
                emissive: new THREE.Color(0xffffff),
                roughness: 0.1,
                metalness: 0.6,
                map: pic2,
                emissiveMap: pic2,
                envMap: env,
              });
              break;
            case '07_金属':
              child.material = new THREE.MeshStandardMaterial({
                color: new THREE.Color(0x757575),
                emissive: new THREE.Color(0x000000),
                roughness: 0,
                metalness: 0.84,
                envMap: env,
              });
              break;
            case '06_塑料接口':
              child.material = new THREE.MeshStandardMaterial({
                color: new THREE.Color(0x050505),
                emissive: new THREE.Color(0x000000),
                roughness: 0.56,
                metalness: 0.66,
                envMap: env,
              });
              break;
            case '03_铰链001':
              child.material = new THREE.MeshStandardMaterial({
                color: new THREE.Color(0x050505),
                emissive: new THREE.Color(0x000000),
                roughness: 0.1,
                metalness: 0.6,
                envMap: env,
              });
              break;
            case '02_屏幕001':
              child.material = new THREE.MeshStandardMaterial({
                color: new THREE.Color(0xffffff),
                emissive: new THREE.Color(0xffffff),
                roughness: 1,
                metalness: 0,
                map: pic3,
                emissiveMap: pic3,
              });
              break;
            case '01_A面001':
              child.material = new THREE.MeshStandardMaterial({
                color: new THREE.Color(0xffffff),
                emissive: new THREE.Color(0x242424),
                roughness: 0.3,
                metalness: 1,
                map: pic1,
                envMap: env,
              });
              break;
            case '金属字':
              child.material = new THREE.MeshStandardMaterial({
                color: new THREE.Color(0xffffff),
                emissive: new THREE.Color(0x000000),
                roughness: 0,
                metalness: 1,
                envMap: env,
              });
              break;
          }
        }
      });

      scene.add(obj);
      obj.scale.set(0.5, 0.5, 0.5);

      obj.rotateX(Math.PI * 0.2);
      obj.rotateY(Math.PI * 0.1);

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.05;
      controls.zoomSpeed = 0.05;
      controls.minDistance = 16;
      controls.maxDistance = 17;

      // const mixer = new THREE.AnimationMixer(model);
      // const action = mixer.clipAction(model.animations[0]);
      // action.enabled = true;
      // action.setEffectiveTimeScale(1);
      // action.setEffectiveWeight(1);
      // action.play();

      // const clock = new THREE.Clock();

      const render = () => {
        window.requestAnimationFrame(render);

        // const delta = clock.getDelta();
        // mixer.update(delta);

        renderer.render(scene, camera);
      };

      const updateCanvas = ({ coordinates, fist, hasHand }) => {
        // cursor.value.textContent = `
        //   fist: ${info.poses.fistProb.toFixed(2)}
        //   pinch: ${info.poses.pinchProb.toFixed(2)}
        // `;

        if (hasHand) {
          // cursor.value.style.opacity = String(1);
          // cursor.value.style.top = canvas.value.offsetHeight * pos[1] + 'px';
          // cursor.value.style.left = canvas.value.offsetWidth * (1 - pos[0]) + 'px';

          canvas.value.style.transform = `
              translate3d(
                ${coordinates[0]}px,
                ${coordinates[1]}px,
                0px
              )
            `;

          obj.scale.set(0.5 * fist, 0.5 * fist, 0.5 * fist);
          !obj.visible && (obj.visible = true);
        } else {
          // cursor.value.style.opacity = String(0);
          obj.visible && (obj.visible = false);
        }
      };

      await initDetect({
        video: video.value,
        update: (info) => {
          isShow.value && (isShow.value = false);
          // !obj.visible && (obj.visible = true);

          // canvas.value.style.transform = `
          //   translate3d(
          //     ${info.bbox[0] + info.bbox[2] / 2 - canvas.value.offsetWidth / 2}px,
          //     ${info.bbox[1] + info.bbox[3] / 2 - canvas.value.offsetHeight / 2}px,
          //     0px
          //   )
          // `;
          // const scale = transScore(info.label, info.score);
          // obj.scale.set(scale * 0.8, scale * 0.8, scale * 0.8);
          // console.log(
          //   canvas.value.offsetWidth * info.coordinates[0][0],
          //   canvas.value.offsetHeight * info.coordinates[0][1],
          //   info.poses.pinchProb,
          // );

          const { coordinates, fist, hasHand } = info;
          console.log(coordinates[0]);

          updateCanvas({
            coordinates: [
              // canvas.value.offsetWidth * (1 - coordinates[0] - 0.5),
              // canvas.value.offsetHeight * (coordinates[1] - 0.3),
              coordinates[0] - canvas.value.offsetWidth * 0.5,
              coordinates[1] - canvas.value.offsetHeight * 0.5,
            ],
            fist: 1,
            hasHand: true,
          });
        },
      });
      render();
    });

    return { canvas, video, isShow, cursor };
  },
};
</script>

<style lang="stylus" scoped>
.wrapper
  width 100vw
  height 100vh
  position relative
  overflow hidden

  .video
  .canvas
  .poster
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    object-fit cover
    transition transform .1s

  .poster
    background purple
    color white
    display flex
    justify-content center
    align-items center
    font-weight 700
    font-size 10vw

  .cursor
    position absolute
    width 50px
    height 50px
    border-radius 50%
    background yellow
    top 0
    left 0
    z-index 99
    opacity 0
</style>
